import React, { useState } from 'react';
import './DashboardWijtmanVoertuigAanmaken.css'; // For custom styles

function DashboardWijtmanVoertuigAanmaken() {
    // State for the form fields
    const [kenteken, setKenteken] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [bouwjaar, setBouwjaar] = useState('');

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Log the vehicle data or send it to a server
        console.log({ kenteken, vehicleType, bouwjaar });
        // Reset the form after submission
        setKenteken('');
        setVehicleType('');
        setBouwjaar('');
    };

    return (
        <div className="DashboardWijtmanVoertuigAanmaken">
            <h1>Aanmaken</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Kenteken:
                        <input
                            type="text"
                            value={kenteken}
                            onChange={(e) => setKenteken(e.target.value)}
                            required
                        />
                    </label>
                </div>

                <div className="vehicle-type-selection">
                    <p>Soort voertuig:</p>
                    <div className="vehicle-type-boxes">
                        {['Trekker', 'Aanhanger'].map((type) => (
                            <div
                                key={type}
                                className={`box ${vehicleType === type ? 'selected' : ''}`}
                                onClick={() => setVehicleType(type)}
                            >
                                {type}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <label>
                        Bouwjaar:
                        <input
                            type="number"
                            value={bouwjaar}
                            onChange={(e) => setBouwjaar(e.target.value)}
                            required
                        />
                    </label>
                </div>

                <button type="submit">Verstuur</button>
            </form>
        </div>
    );
}

export default DashboardWijtmanVoertuigAanmaken;
