// HeroSection.js

import React from 'react';
import './HeroSection.css';
import {useNavigate} from "react-router-dom";
import {IoEnterOutline} from "react-icons/io5";

const HeroSection = () => {
    const navigate = useNavigate();

    const handleRedirectConsult = () => {
        navigate('/open-sollicitatie');
    };

    return (
        <div className="hero-section">
            <h1>Software Begraafplaatswerken</h1>
            <p>
                Software voor het aanmelden en plannen van werk dat wordt verricht op de begraafplaats.
            </p>
            <button onClick={handleRedirectConsult} className="rondleiding-button">
                Actie knop  {/*insert space*/}{/*<IoEnterOutline  className="rondleiding-icon"/>*/}
            </button>
        </div>
    );
};

export default HeroSection;
