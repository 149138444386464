import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation for route detection
import './Dashboard.css';
import DashboardVoertuigen from './Beheren/DashboardVoertuigen';
import DashboardSettings from './DashboardSettings';
import DashboardWijtmanPlanningMaken from "./DashboardWijtmanPlanningMaken";
import DashboardWijtmanFillablePdf from "./DashboardWijtmanFillablePdf";
import DashboardWijtmanPersoneelAanmaken from "./Aanmaken/DashboardWijtmanPersoneelAanmaken";
import DashboardWijtmanVoertuigAanmaken from "./Aanmaken/DashboardWijtmanVoertuigAanmaken";
import DashboardWijtmanMachineAanmaken from "./Aanmaken/DashboardWijtmanMachineAanmaken";
import DashboardHome from "./DashboardHome";
import DashboardWijtmanKlantAanmaken from "./Aanmaken/DashboardWijtmanKlantAanmaken";
import DashboardPersoneelsleden from "./Beheren/DashboardPersoneelsleden";
import DashboardMachines from "./Beheren/DashboardMachines";
import DashboardKlanten from "./Beheren/DashboardKlanten";
import DashboardOpdrachtgeverAanmaken from "./Aanmaken/DashboardOpdrachtgeverAanmaken";
import DashboardContactPersoonAanmaken from "./Aanmaken/DashboardContactPersoonAanmaken";
import DashboardOpdrachtgevers from "./Beheren/DashboardOpdrachtgevers";
import DashboardContactPersonen from "./Beheren/DashboardContactPersonen";
import DashboardPhoneBook from "./DashboardPhoneBook";

function Dashboard() {
    const [currentView, setCurrentView] = useState('Home');
    const [vacancyCounts, setVacancyCounts] = useState({
        total: 0,
        enabled: 0,
        disabled: 0
    });
    const [showAanmaken, setShowAanmaken] = useState(true);
    const [showBeheren, setShowBeheren] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true); // State for sidebar open/close
    const location = useLocation(); // Get the current location

    useEffect(() => {
        const isAuth = localStorage.getItem('isAuth');

        // Load counts from localStorage
        const total = localStorage.getItem('totalVacs') || 0;
        const enabled = localStorage.getItem('enabledVacs') || 0;
        const disabled = localStorage.getItem('disabledVacs') || 0;

        setVacancyCounts({ total: Number(total), enabled: Number(enabled), disabled: Number(disabled) });
    }, []);

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev); // Toggle sidebar state
    };
    // Determine if we need to slide up based on the current route
    const isDashboardRoute = location.pathname === '/dashboard';
    return (
        <div className={`dashboard-container ${isDashboardRoute ? 'dashboard-slide-up' : ''}`}>
            <aside className={`dashboard-sidebar ${sidebarOpen ? 'open' : 'closed'}`}>
                <nav>
                    <ul>
                        <li onClick={() => handleViewChange('Home')}>Home</li>
                        <h3 className="dashboard-sidebar-section-header">Live</h3>
                        <li onClick={() => handleViewChange('Planning')}>Planning maken</li>

                        <h3
                            className="dashboard-sidebar-section-header"
                            onClick={() => setShowAanmaken(prev => !prev)}
                            style={{ cursor: 'pointer' }}
                        >
                            Aanmaken {showAanmaken ? '▲' : '▼'}
                        </h3>
                        {showAanmaken && (
                            <>
                                <li onClick={() => handleViewChange('Personeel')}>Personeel</li>
                                <li onClick={() => handleViewChange('Voertuig')}>Voertuig</li>
                                <li onClick={() => handleViewChange('Machine')}>Machine</li>
                                <li onClick={() => handleViewChange('Opdrachtgever')}>Opdrachtgever</li>
                                <li onClick={() => handleViewChange('Klant')}>Klant</li>
                                <li onClick={() => handleViewChange('Contactpersoon')}>Contactpersoon</li>
                            </>
                        )}

                        <h3
                            className="dashboard-sidebar-section-header"
                            onClick={() => setShowBeheren(prev => !prev)}
                            style={{ cursor: 'pointer' }}
                        >
                            Beheren {showBeheren ? '▲' : '▼'}
                        </h3>
                        {showBeheren && (
                            <>
                                <li onClick={() => handleViewChange('Machines')}>Machines</li>
                                <li onClick={() => handleViewChange('Voertuigen')}>Voertuigen</li>
                                <li onClick={() => handleViewChange('Personeelsleden')}>Personeelsleden</li>
                                <li onClick={() => handleViewChange('Klanten')}>Klanten</li>
                                <li onClick={() => handleViewChange('Opdrachtgevers')}>Opdrachtgevers</li>
                                <li onClick={() => handleViewChange('Contactpersonen')}>Contactpersonen</li>
                            </>
                        )}

                        <br />
                        <li onClick={() => handleViewChange('Telefoonboek')}>Telefoonboek</li>
                        <li onClick={() => handleViewChange('Aanmeldformulier')}>Aanmeldformulier</li>
                        <li onClick={() => handleViewChange('Settings')}>Instellingen</li>
                    </ul>
                </nav>
            </aside>
            <main className="dashboard-main">
                <header className="dashboard-header">
                    <div className="dashboard-controls">
                        <button onClick={toggleSidebar}>
                            {sidebarOpen ? 'Sluit Sidebar' : 'Open Sidebar'}
                        </button>
                        <button onClick={() => handleViewChange(currentView)}>Refresh</button>
                    </div>
                    <h1>{currentView}</h1>
                </header>

                {currentView === 'Home' && <DashboardHome />}
                {currentView === 'Planning' && <DashboardWijtmanPlanningMaken />}
                {currentView === 'Machine' && <DashboardWijtmanMachineAanmaken />}
                {currentView === 'Voertuig' && <DashboardWijtmanVoertuigAanmaken />}
                {currentView === 'Personeel' && <DashboardWijtmanPersoneelAanmaken />}
                {currentView === 'Klant' && <DashboardWijtmanKlantAanmaken />}
                {currentView === 'Contactpersoon' && <DashboardContactPersoonAanmaken />}
                {currentView === 'Opdrachtgever' && <DashboardOpdrachtgeverAanmaken />}
                {currentView === 'Voertuigen' && <DashboardVoertuigen />}
                {currentView === 'Machines' && <DashboardMachines />}
                {currentView === 'Personeelsleden' && <DashboardPersoneelsleden />}
                {currentView === 'Klanten' && <DashboardKlanten />}
                {currentView === 'Contactpersonen' && <DashboardContactPersonen />}
                {currentView === 'Opdrachtgevers' && <DashboardOpdrachtgevers />}
                {currentView === 'Telefoonboek' && <DashboardPhoneBook />}
                {currentView === 'Aanmeldformulier' && <DashboardWijtmanFillablePdf />}
                {currentView === 'Settings' && <DashboardSettings />}
            </main>
        </div>
    );
}

export default Dashboard;
