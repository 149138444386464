import React from 'react';

function DashboardMachines() {
    return (
        <div className="DashboardMachines">
            {/* Your application code here */}
        </div>
    );
}

export default DashboardMachines;
