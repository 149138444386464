import React from 'react';
import { PDFDocument, rgb, degrees } from 'pdf-lib';
import { saveAs } from 'file-saver';
import './DashboardWijtmanFillablePdf.css';

const DashboardWijtmanFillablePdf = () => {
    const createPdf = async () => {
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Add a page to the document with A4 size
        const page = pdfDoc.addPage([595, 842]); // A4 dimensions
// Draw watermark
        const watermarkText = 'DRAFT'; // Watermark text
        page.drawText(watermarkText, {
            x: 200,
            y: 400,
            size: 60,
            color: rgb(0.5, 0.5, 0.5), // Light gray color
            opacity: 0.5, // Semi-transparent
            rotate: degrees(-45), // Rotate the text
        });

        // Draw the main title on the page
        page.drawText('Begraafplaats Formulier', {
            x: 50,
            y: 800,
            size: 20,
            color: rgb(0, 0, 0),
        });

        const form = pdfDoc.getForm();

        // Form fields and labels

        // 1. Begraafplaats: Oud of nieuw (Checkboxes)
        page.drawText('Begraafplaats', { x: 50, y: 760, size: 12 });
        const oudCheckBox = form.createCheckBox('begraafplaats_oud');
        oudCheckBox.addToPage(page, { x: 250, y: 755, width: 15, height: 15 });
        page.drawText('Oud', { x: 270, y: 755, size: 12 });

        const nieuwCheckBox = form.createCheckBox('begraafplaats_nieuw');
        nieuwCheckBox.addToPage(page, { x: 350, y: 755, width: 15, height: 15 });
        page.drawText('Nieuw', { x: 370, y: 755, size: 12 });

        // 2. Datum begrafenis
        page.drawText('Datum begrafenis:', { x: 50, y: 730, size: 12 });
        const datumBegrafenisField = form.createTextField('datum_begrafenis');
        datumBegrafenisField.addToPage(page, { x: 250, y: 730, width: 150, height: 20 });

        // 3. Tijd begrafenis
        page.drawText('Tijd begrafenis:', { x: 50, y: 700, size: 12 });
        const tijdBegrafenisField = form.createTextField('tijd_begrafenis');
        tijdBegrafenisField.addToPage(page, { x: 250, y: 700, width: 150, height: 20 });

        // 4. Dag begrafenis: Ma/Di/Wo/Do/Vrij/Za/Zo (Checkboxes)
        const dagOptions = ['Ma', 'Di', 'Wo', 'Do', 'Vrij', 'Za', 'Zo'];
        page.drawText('Dag begrafenis:', { x: 50, y: 670, size: 12 });
        dagOptions.forEach((day, index) => {
            const checkBox = form.createCheckBox(`dag_begrafenis_${day}`);
            checkBox.addToPage(page, { x: 250 + index * 30, y: 660, width: 15, height: 15 });
            page.drawText(day, { x: 265 + index * 30, y: 660, size: 12 });
        });

        // 5. Begraven om
        page.drawText('Begraven om:', { x: 50, y: 630, size: 12 });
        const begravenOmField = form.createTextField('begraven_om');
        begravenOmField.addToPage(page, { x: 250, y: 630, width: 100, height: 20 });

        // 6. Sluiten om
        page.drawText('Sluiten om:', { x: 400, y: 630, size: 12 });
        const sluitenOmField = form.createTextField('sluiten_om');
        sluitenOmField.addToPage(page, { x: 470, y: 630, width: 100, height: 20 });

// 7. Aula dienst: Tijd of n.v.t. (Checkboxes)
        page.drawText('Aula diensttijd of n.v.t', { x: 50, y: 600, size: 12 });
        const aulaDienstField = form.createTextField('aula_dienst');
        aulaDienstField.addToPage(page, { x: 250, y: 600, width: 150, height: 20 });

        const nvtCheckBox = form.createCheckBox('aula_dienst_nvt');
        nvtCheckBox.addToPage(page, { x: 410, y: 600, width: 15, height: 15 });
        page.drawText('n.v.t.', { x: 430, y: 600, size: 12 });


        // 8. Soort graf: Alg/Part/Bijzetting/Grafkelder/Kindergraf (Checkboxes)
        const soortGrafOptions = ['Alg', 'Part', 'Bijzetting', 'Grafkelder', 'Kindergraf'];
        page.drawText('Soort graf:', { x: 50, y: 570, size: 12 });
        soortGrafOptions.forEach((option, index) => {
            const checkBox = form.createCheckBox(`soort_graf_${option}`);
            checkBox.addToPage(page, { x: 250 + index * 50, y: 560, width: 15, height: 15 });
            page.drawText(option, { x: 270 + index * 50, y: 560, size: 12 });
        });

        // 9. Graf op voorraad: Ja/Nee (Checkboxes)
        page.drawText('Graf op voorraad:', { x: 50, y: 530, size: 12 });
        const jaCheckBox = form.createCheckBox('graf_op_voorraad_ja');
        jaCheckBox.addToPage(page, { x: 250, y: 525, width: 15, height: 15 });
        page.drawText('Ja', { x: 270, y: 525, size: 12 });

        const neeCheckBox = form.createCheckBox('graf_op_voorraad_nee');
        neeCheckBox.addToPage(page, { x: 350, y: 525, width: 15, height: 15 });
        page.drawText('Nee', { x: 370, y: 525, size: 12 });

        // 10. Naam overledene
        page.drawText('Naam overledene:', { x: 50, y: 490, size: 12 });
        const naamOverledeneField = form.createTextField('naam_overledene');
        naamOverledeneField.addToPage(page, { x: 250, y: 490, width: 200, height: 20 });

        // 11. Diepte: 1/2/3/4 (Checkboxes)
        page.drawText('Diepte:', { x: 50, y: 460, size: 12 });
        const diepteOptions = ['1', '2', '3', '4'];
        diepteOptions.forEach((option, index) => {
            const checkBox = form.createCheckBox(`diepte_${option}`);
            checkBox.addToPage(page, { x: 250 + index * 30, y: 455, width: 15, height: 15 });
            page.drawText(option, { x: 265 + index * 30, y: 455, size: 12 });
        });

// 12. Overputten: Ja/Nee (Checkboxes)
        page.drawText('Overputten:', { x: 50, y: 430, size: 12 });
        const overputtenJaCheckBox = form.createCheckBox('overputten_ja');
        overputtenJaCheckBox.addToPage(page, { x: 250, y: 425, width: 15, height: 15 });
        page.drawText('Ja', { x: 270, y: 425, size: 12 });

        const overputtenNeeCheckBox = form.createCheckBox('overputten_nee');
        overputtenNeeCheckBox.addToPage(page, { x: 350, y: 425, width: 15, height: 15 });
        page.drawText('Nee', { x: 370, y: 425, size: 12 });

        // 13. Ruimen: 1/2/3/4, Aantal overledenen: 1/2/3/4 (Checkboxes)
        page.drawText('Ruimen:', { x: 50, y: 400, size: 12 });
        const ruimenOptions = ['1', '2', '3', '4'];
        ruimenOptions.forEach((option, index) => {
            const checkBox = form.createCheckBox(`ruimen_${option}`);
            checkBox.addToPage(page, { x: 250 + index * 30, y: 395, width: 15, height: 15 });
            page.drawText(option, { x: 265 + index * 30, y: 395, size: 12 });
        });

        page.drawText('Aantal ovl:', { x: 400, y: 400, size: 12 });
        const aantalOverledenenOptions = ['1', '2', '3', '4'];
        aantalOverledenenOptions.forEach((option, index) => {
            const checkBox = form.createCheckBox(`aantal_overledenen_${option}`);
            checkBox.addToPage(page, { x: 480 + index * 30, y: 395, width: 15, height: 15 });
            page.drawText(option, { x: 495 + index * 30, y: 395, size: 12 });
        });

        // 14. Schudden: 1/2/3/4, Aantal overledenen: 1/2/3/4 (Checkboxes)
        page.drawText('Schudden:', { x: 50, y: 370, size: 12 });
        const schuddenOptions = ['1', '2', '3', '4'];
        schuddenOptions.forEach((option, index) => {
            const checkBox = form.createCheckBox(`schudden_${option}`);
            checkBox.addToPage(page, { x: 250 + index * 30, y: 365, width: 15, height: 15 });
            page.drawText(option, { x: 265 + index * 30, y: 365, size: 12 });
        });

        page.drawText('Aantal ovl:', { x: 400, y: 370, size: 12 });
        aantalOverledenenOptions.forEach((option, index) => {
            const checkBox = form.createCheckBox(`aantal_schudden_${option}`);
            checkBox.addToPage(page, { x: 480 + index * 30, y: 365, width: 15, height: 15 });
            page.drawText(option, { x: 495 + index * 30, y: 365, size: 12 });
        });

        // 15. Monument verwijderen: Ja/Nee/Steenhouwer (Checkboxes)
        page.drawText('Monument verwijderen:', { x: 50, y: 340, size: 12 });
        const monumentVerwijderenOptions = ['Ja', 'Nee', 'Steenhouwer'];
        monumentVerwijderenOptions.forEach((option, index) => {
            const checkBox = form.createCheckBox(`monument_verwijderen_${option}`);
            checkBox.addToPage(page, { x: 250 + index * 50, y: 335, width: 15, height: 15 });
            page.drawText(option, { x: 270 + index * 50, y: 335, size: 12 });
        });

        // 16. Monument opmerking
        page.drawText('Monument opmerking:', { x: 50, y: 300, size: 12 });
        const monumentOpmerkingField = form.createTextField('monument_opmerking');
        monumentOpmerkingField.addToPage(page, { x: 250, y: 300, width: 300, height: 20 });

        // 17. Naastgelegen graven
        page.drawText('Naastgelegen graven:', { x: 50, y: 270, size: 12 });
        const naastgelegenGravenField = form.createTextField('naastgelegen_graven');
        naastgelegenGravenField.addToPage(page, { x: 250, y: 270, width: 200, height: 20 });

        // 18. Asbussen in het graf: Ja/Nee
        page.drawText('Asbussen in het graf:', { x: 50, y: 240, size: 12 });
        const asbussenField = form.createCheckBox('asbussen');
        asbussenField.addToPage(page, { x: 250, y: 235, width: 15, height: 15 });
        page.drawText('Ja', { x: 270, y: 235, size: 12 });

        const asbussenNeeField = form.createCheckBox('asbussen_nee');
        asbussenNeeField.addToPage(page, { x: 350, y: 235, width: 15, height: 15 });
        page.drawText('Nee', { x: 370, y: 235, size: 12 });

        // 19. Lift plaatsen: Ja/Nee
        page.drawText('Lift plaatsen:', { x: 50, y: 210, size: 12 });
        const liftPlaatsenField = form.createCheckBox('lift_plaatsen');
        liftPlaatsenField.addToPage(page, { x: 250, y: 205, width: 15, height: 15 });
        page.drawText('Ja', { x: 270, y: 205, size: 12 });

        const liftPlaatsenNeeField = form.createCheckBox('lift_plaatsen_nee');
        liftPlaatsenNeeField.addToPage(page, { x: 350, y: 205, width: 15, height: 15 });
        page.drawText('Nee', { x: 370, y: 205, size: 12 });

        // 20. Dalen met touwen: Ja/Nee
        page.drawText('Dalen met touwen:', { x: 50, y: 180, size: 12 });
        const dalenTouwenField = form.createCheckBox('dalen_touwen');
        dalenTouwenField.addToPage(page, { x: 250, y: 175, width: 15, height: 15 });
        page.drawText('Ja', { x: 270, y: 175, size: 12 });

        const dalenTouwenNeeField = form.createCheckBox('dalen_touwen_nee');
        dalenTouwenNeeField.addToPage(page, { x: 350, y: 175, width: 15, height: 15 });
        page.drawText('Nee', { x: 370, y: 175, size: 12 });

        // 21. Grafgroen: Ja/Nee
        page.drawText('Grafgroen:', { x: 50, y: 150, size: 12 });
        const grafgroenField = form.createCheckBox('grafgroen');
        grafgroenField.addToPage(page, { x: 250, y: 145, width: 15, height: 15 });
        page.drawText('Ja', { x: 270, y: 145, size: 12 });

        const grafgroenNeeField = form.createCheckBox('grafgroen_nee');
        grafgroenNeeField.addToPage(page, { x: 350, y: 145, width: 15, height: 15 });
        page.drawText('Nee', { x: 370, y: 145, size: 12 });

        // 22. Emmertje zand en schepje: Ja/Nee
        page.drawText('Emmertje zand en schepje:', { x: 50, y: 120, size: 12 });
        const emmertjeZandField = form.createCheckBox('emmertje_zand');
        emmertjeZandField.addToPage(page, { x: 250, y: 115, width: 15, height: 15 });
        page.drawText('Ja', { x: 270, y: 115, size: 12 });

        const emmertjeZandNeeField = form.createCheckBox('emmertje_zand_nee');
        emmertjeZandNeeField.addToPage(page, { x: 350, y: 115, width: 15, height: 15 });
        page.drawText('Nee', { x: 370, y: 115, size: 12 });

        // 23. Overmaatse kostmaat: Ja/Nee
        page.drawText('Overmaatse kostmaat:', { x: 50, y: 90, size: 12 });
        const overmaatseKostmaatField = form.createCheckBox('overmaatse_kostmaat');
        overmaatseKostmaatField.addToPage(page, { x: 250, y: 85, width: 15, height: 15 });
        page.drawText('Ja', { x: 270, y: 85, size: 12 });

        const overmaatseKostmaatNeeField = form.createCheckBox('overmaatse_kostmaat_nee');
        overmaatseKostmaatNeeField.addToPage(page, { x: 350, y: 85, width: 15, height: 15 });
        page.drawText('Nee', { x: 370, y: 85, size: 12 });

        // 24. Opmerkingen
        page.drawText('Opmerkingen:', { x: 50, y: 60, size: 12 });
        const opmerkingenField = form.createTextField('opmerkingen');
        opmerkingenField.addToPage(page, { x: 250, y: 60, width: 300, height: 20 });

        // 25. Overige bijzonderheden
        page.drawText('Overige bijzonderheden:', { x: 50, y: 30, size: 12 });
        const overigeBijzonderhedenField = form.createTextField('overige_bijzonderheden');
        overigeBijzonderhedenField.addToPage(page, { x: 250, y: 30, width: 300, height: 20 });

        // Save the PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        saveAs(blob, 'begraafplaats_form.pdf');
    };

    return (
        <div className="DashboardWijtmanFillablePdf">

            <h1>Digitaal Aanmeldformulier</h1>
            <h2>Voor aanvraag van werkzaamheden op de begraafplaats.</h2>
            <button onClick={createPdf}>Download PDF Form</button>

            
        </div>
    );
};

export default DashboardWijtmanFillablePdf;



