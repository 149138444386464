import React from 'react';

function DashboardPersoneelsleden() {
    return (
        <div className="DashboardPersoneelsleden">
            {/* Your application code here */}
        </div>
    );
}

export default DashboardPersoneelsleden;
