import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios
import './DashboardWijtmanKlantAanmaken.css';

function DashboardWijtmanKlantAanmaken() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    // State om de klantgegevens op te slaan
    const [klant, setKlant] = useState({
        klantNaam: '',
        phoneNumber: '',
        assignedOpdrachtgever: '', // Nieuw veld voor opdrachtgever
        email: '',
        address: '',
        zipcode: '',
        housenumber: '',
        city: ''
    });

    // State voor beschikbare opdrachtgevers
    const [beschikbareOpdrachtgevers, setBeschikbareOpdrachtgevers] = useState([]);

    // State voor indienen feedback
    const [submissionStatus, setSubmissionStatus] = useState('');

    // Fetch beschikbare opdrachtgevers from API on component mount
    useEffect(() => {
        const fetchOpdrachtgevers = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/opdrachtgever/all`);
                const opdrachtgevers = response.data.map(opdrachtgever => opdrachtgever.opdrachtgeverNaam); // Assuming opdrachtgeverNaam exists
                setBeschikbareOpdrachtgevers(opdrachtgevers);
            } catch (error) {
                console.error('Er is een fout opgetreden bij het ophalen van opdrachtgevers:', error);
            }
        };

        fetchOpdrachtgevers();
    }, [API_BASE_URL]);

    // Handler voor veranderingen in de invoervelden
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setKlant({ ...klant, [name]: value });
    };

    // Handelen van formulierindiening
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/klant/create`, klant);
            console.log('Klant succesvol aangemaakt:', response.data);
            setSubmissionStatus('Klant succesvol aangemaakt!');

            // Reset de invoervelden
            setKlant({ klantNaam: '', phoneNumber: '', assignedOpdrachtgever: '' });
        } catch (error) {
            console.error('Er is een fout opgetreden bij het aanmaken van de klant:', error);
            setSubmissionStatus('Er is een fout opgetreden bij het aanmaken van de klant.');
        }
    };

    return (
        <div className="DashboardWijtmanKlantAanmaken">
            <h2>Klant Aanmaken</h2>
            {submissionStatus && <div className="submission-status">{submissionStatus}</div>}
            <form onSubmit={handleSubmit}>
                {/* Naam Invoer */}
                <div className="form-group">
                    <label htmlFor="klantNaam">Naam</label>
                    <input
                        type="text"
                        id="klantNaam"
                        name="klantNaam"
                        value={klant.klantNaam}
                        onChange={handleInputChange}
                        placeholder="Vul de naam van de klant in"
                        required
                    />
                </div>
                {/* Telefoonnummer Invoer */}
                <div className="form-group">
                    <label htmlFor="phoneNumber">Telefoonnummer</label>
                    <input
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={klant.phoneNumber}
                        onChange={handleInputChange}
                        placeholder="Vul het telefoonnummer van de klant in"
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="klantNaam">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={klant.email}
                        onChange={handleInputChange}
                        placeholder="Vul de email van de klant in"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="klantNaam">Adres</label>
                    <input
                        type="text"
                        id="address"
                        name="address"
                        value={klant.address}
                        onChange={handleInputChange}
                        placeholder="Vul het adres van de klant in"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="zipcode">Postcode</label>
                    <input
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        value={klant.zipcode}
                        onChange={handleInputChange}
                        placeholder="Vul de postcode van de klant in"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="housenumber">Huisnummer</label>
                    <input
                        type="text"
                        id="housenumber"
                        name="housenumber"
                        value={klant.housenumber}
                        onChange={handleInputChange}
                        placeholder="Vul het huisnummer van de klant in"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="city">Plaats</label>
                    <input
                        type="text"
                        id="city"
                        name="city"
                        value={klant.city}
                        onChange={handleInputChange}
                        placeholder="Vul de plaats van de klant in"
                        required
                    />
                </div>


                {/* Opdrachtgever Dropdown */}
                <div className="form-group">
                    <label htmlFor="assignedOpdrachtgever">Opdrachtgever</label>
                    <select
                        id="assignedOpdrachtgever"
                        name="assignedOpdrachtgever"
                        value={klant.assignedOpdrachtgever}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Selecteer een opdrachtgever</option>
                        {beschikbareOpdrachtgevers.map((opdrachtgever, index) => (
                            <option key={index} value={opdrachtgever}>
                                {opdrachtgever}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Klant aanmaken knop */}
                <button type="submit" className="create-client-button">
                    Klant Aanmaken
                </button>
            </form>
        </div>
    );
}

export default DashboardWijtmanKlantAanmaken;
