import React, { useState } from 'react';
import './DashboardWijtmanPlanningMaken.css';

function DashboardWijtmanPlanningMaken() {
    const [vehicles, setVehicles] = useState([]);
    const [machinist, setMachinist] = useState('');
    const [secondMan, setSecondMan] = useState('');
    const [customers, setCustomers] = useState([{ customerName: '', taskModules: [{ module: '', gravenumber: '' }] }]);
    const [completed, setCompleted] = useState(false);

    // Mock data for selectable options
    const availableCustomers = ['Klant 1', 'Klant 2', 'Klant 3', 'Klant 4'];
    const availableTaskModules = ['Module A', 'Module B', 'Module C', 'Module D'];

    // Handle vehicle selection
    const handleVehicleChange = (event) => {
        const selectedVehicles = [...event.target.selectedOptions].map(option => option.value);
        setVehicles(selectedVehicles);
    };

    // Add new customer
    const handleAddCustomer = () => {
        setCustomers([...customers, { customerName: '', taskModules: [{ module: '', gravenumber: '' }] }]);
    };

    // Handle customer name change
    const handleCustomerChange = (index, value) => {
        const updatedCustomers = customers.map((customer, i) =>
            i === index ? { ...customer, customerName: value } : customer
        );
        setCustomers(updatedCustomers);
    };

    // Add new task module to a specific customer
    const handleAddTaskModule = (index) => {
        const updatedCustomers = customers.map((customer, i) =>
            i === index ? { ...customer, taskModules: [...customer.taskModules, { module: '', gravenumber: '' }] } : customer
        );
        setCustomers(updatedCustomers);
    };

    // Handle task module change
    const handleTaskModuleChange = (customerIndex, taskIndex, field, value) => {
        const updatedCustomers = customers.map((customer, i) => {
            if (i === customerIndex) {
                const updatedTaskModules = customer.taskModules.map((task, j) =>
                    j === taskIndex ? { ...task, [field]: value } : task
                );
                return { ...customer, taskModules: updatedTaskModules };
            }
            return customer;
        });
        setCustomers(updatedCustomers);
    };

    const handleComplete = () => {
        setCompleted(true);
    };

    const handleEditPlan = () => {
        setCompleted(false); // Reopen the planning if needed
    };

    const handleRemovePlan = () => {
        // Reset all the planning
        setVehicles([]);
        setMachinist('');
        setSecondMan('');
        setCustomers([{ customerName: '', taskModules: [{ module: '', gravenumber: '' }] }]);
        setCompleted(false);
    };

    return (
        <div className="DashboardWijtmanPlanningMaken">
            <h1>Planning Maken</h1>

            {/* Step 1: Select Vehicles */}
            <div>
                <label>Selecteer Voertuigen: </label>
                <select multiple={true} value={vehicles} onChange={handleVehicleChange}>
                    <option value="vehicle1">Voertuig 1</option>
                    <option value="vehicle2">Voertuig 2</option>
                    <option value="vehicle3">Voertuig 3</option>
                    {/* Add more vehicles as needed */}
                </select>
            </div>

            {/* Step 2: Select Machinist and 2nd Man */}
            <div>
                <label>Selecteer Machinist: </label>
                <select value={machinist} onChange={(e) => setMachinist(e.target.value)}>
                    <option value="">--Selecteer Machinist--</option>
                    <option value="machinist1">Machinist 1</option>
                    <option value="machinist2">Machinist 2</option>
                    {/* Add more machinists */}
                </select>
            </div>

            <div>
                <label>Selecteer 2e Man: </label>
                <select value={secondMan} onChange={(e) => setSecondMan(e.target.value)}>
                    <option value="">--Selecteer 2e Man--</option>
                    <option value="secondman1">2e Man 1</option>
                    <option value="secondman2">2e Man 2</option>
                    {/* Add more options */}
                </select>
            </div>

            {/* Step 3 and 4: Customers and Task Modules */}
            <div>
                <h3>Customers and Task Modules</h3>
                {customers.map((customer, customerIndex) => (
                    <div key={customerIndex} className="customer-section">
                        <label>Selecteer Klant: </label>
                        <select
                            value={customer.customerName}
                            onChange={(e) => handleCustomerChange(customerIndex, e.target.value)}
                        >
                            <option value="">--Selecteer Klant--</option>
                            {availableCustomers.map((customerName, index) => (
                                <option key={index} value={customerName}>
                                    {customerName}
                                </option>
                            ))}
                        </select>

                        <h4>Selecteer Taakmodules en Grafnummer:</h4>
                        {customer.taskModules.map((taskModule, taskIndex) => (
                            <div key={taskIndex}>
                                <label>Task Module {taskIndex + 1}: </label>
                                <select
                                    value={taskModule.module}
                                    onChange={(e) => handleTaskModuleChange(customerIndex, taskIndex, 'module', e.target.value)}
                                >
                                    <option value="">--Selecteer Module--</option>
                                    {availableTaskModules.map((module, index) => (
                                        <option key={index} value={module}>
                                            {module}
                                        </option>
                                    ))}
                                </select>

                                {/* Optional Gravenumber input */}
                                <label>Grafnummer (optioneel): </label>
                                <input
                                    type="text"
                                    value={taskModule.gravenumber}
                                    placeholder="Vul Grafnummer in"
                                    onChange={(e) => handleTaskModuleChange(customerIndex, taskIndex, 'gravenumber', e.target.value)}
                                />
                            </div>
                        ))}

                        <button onClick={() => handleAddTaskModule(customerIndex)}>Add Task Module</button>
                    </div>
                ))}

                <button onClick={handleAddCustomer}>Add Another Customer</button>
            </div>

            {/* Step 5: Complete Button */}
            {!completed && (
                <button onClick={handleComplete} disabled={!vehicles.length || !machinist || !secondMan || !customers.length}>
                    Afronden
                </button>
            )}

            {/* Step 6: Edit or Remove Plan */}
            {completed && (
                <div>
                    <p>Plan Completed!</p>
                    <button onClick={handleEditPlan}>Wijzig</button>
                    <button onClick={handleRemovePlan}>Verwijder</button>
                </div>
            )}
        </div>
    );
}

export default DashboardWijtmanPlanningMaken;
