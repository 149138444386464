import React from 'react';

function DashboardOpdrachtgevers() {
    return (
        <div className="DashboardOpdrachtgevers">
            {/* Your application code here */}
        </div>
    );
}

export default DashboardOpdrachtgevers;
