import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios
import './DashboardContactPersoonAanmaken.css';

function DashboardContactPersoonAanmaken() {
    const API_BASE_URL = process.env.REACT_APP_API_HOST;

    // State voor contactpersoongegevens
    const [contactpersoon, setContactpersoon] = useState({
        contactPersoonNaam: '',
        phoneNumber: '',
        assignedKlant: [''], // Array to handle multiple clients
        email: ''
    });

    // State voor beschikbare klanten
    const [beschikbareKlanten, setBeschikbareKlanten] = useState([]); // Changed to state

    // Fetch available clients from API on component mount
    useEffect(() => {
        const fetchKlanten = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/klant/all`);
                const klanten = response.data.map(klant => klant.klantNaam); // Assuming klantNaam exists
                setBeschikbareKlanten(klanten);
            } catch (error) {
                console.error('Er is een fout opgetreden bij het ophalen van klanten:', error);
            }
        };

        fetchKlanten();
    }, [API_BASE_URL]);

    // Handler voor wijzigingen in contactpersoongegevens
    const handleChange = (e) => {
        setContactpersoon({ ...contactpersoon, [e.target.name]: e.target.value });
    };

    // Handler voor wijzigingen in gekoppelde klanten
    const handleKlantChange = (index, e) => {
        const updatedKlanten = [...contactpersoon.assignedKlant];
        updatedKlanten[index] = e.target.value;
        setContactpersoon({ ...contactpersoon, assignedKlant: updatedKlanten });
    };

    // Toevoegen van een nieuwe klant aan de gekoppelde klantenlijst
    const addKlant = () => {
        setContactpersoon({ ...contactpersoon, assignedKlant: [...contactpersoon.assignedKlant, ''] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Convert the array of selected clients to a single string (comma-separated)
        const assignedKlantString = contactpersoon.assignedKlant.join(', ');

        // Create the updated contactpersoon object with the assignedKlant as a string
        const updatedContactpersoon = {
            ...contactpersoon,
            assignedKlant: assignedKlantString
        };

        try {
            const response = await axios.post(`${API_BASE_URL}/contactpersoon/create`, updatedContactpersoon);
            console.log('Contactpersoon succesvol aangemaakt:', response.data);
            // Reset form on successful submission
            setContactpersoon({ contactPersoonNaam: '', phoneNumber: '', assignedKlant: [''] });
        } catch (error) {
            console.error('Er is een fout opgetreden bij het aanmaken van de contactpersoon:', error);
            // Handle error (e.g., show error message)
        }
    };

    return (
        <div className="contactpersoon-container">
            <h2>Contactpersoon Aanmaken</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Naam Contactpersoon</label>
                    <input
                        type="text"
                        name="contactPersoonNaam"
                        value={contactpersoon.contactPersoonNaam}
                        onChange={handleChange}
                        placeholder="Naam van de contactpersoon"
                    />
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={contactpersoon.email}
                        onChange={handleChange}
                        placeholder="Email van de contactpersoon"
                    />
                </div>
                <div className="form-group">
                    <label>Telefoonnummer</label>
                    <input
                        type="tel"
                        name="phoneNumber"
                        value={contactpersoon.phoneNumber}
                        onChange={handleChange}
                        placeholder="Telefoonnummer"
                    />
                </div>

                {/* Dynamische lijst van gekoppelde klanten */}
                <div className="form-group">
                    <label>Gekoppelde Klanten</label>
                    {contactpersoon.assignedKlant.map((klant, index) => (
                        <div key={index} className="klant-dropdown">
                            <select
                                value={klant}
                                onChange={(e) => handleKlantChange(index, e)}
                                required
                            >
                                <option value="">Selecteer een klant</option>
                                {beschikbareKlanten.map((beschikbareKlant, klantIndex) => (
                                    <option key={klantIndex} value={beschikbareKlant}>
                                        {beschikbareKlant}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                    <button type="button" onClick={addKlant} className="add-klant-button">
                        Klant toevoegen
                    </button>
                </div>

                <button type="submit" className="submit-button">Opslaan</button>
            </form>
        </div>
    );
}

export default DashboardContactPersoonAanmaken;
