import React, { useState } from 'react';
import './DashboardWijtmanMachineAanmaken.css'; // For custom styles

function DashboardWijtmanMachineAanmaken() {
    // State for the form fields
    const [machineType, setMachineType] = useState('');
    const [kenteken, setKenteken] = useState('');
    const [bouwjaar, setBouwjaar] = useState('');

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Log the machine data or send it to a server
        console.log({ machineType, kenteken, bouwjaar });
        // Reset the form after submission
        setMachineType('');
        setKenteken('');
        setBouwjaar('');
    };

    return (
        <div className="DashboardWijtmanMachineAanmaken">
            <h1>Aanmaken</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Kenteken:
                        <input
                            type="text"
                            value={kenteken}
                            onChange={(e) => setKenteken(e.target.value)}
                            required
                        />
                    </label>
                </div>

                <div className="machine-type-selection">
                    <p>Soort machine:</p>
                    <div className="machine-type-boxes">
                        {['BOKI', 'Nimos', 'Shovel', 'Rupskraan'].map((type) => (
                            <div
                                key={type}
                                className={`box ${machineType === type ? 'selected' : ''}`}
                                onClick={() => setMachineType(type)}
                            >
                                {type}
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <label>
                        Bouwjaar:
                        <input
                            type="number"
                            value={bouwjaar}
                            onChange={(e) => setBouwjaar(e.target.value)}
                            required
                        />
                    </label>
                </div>

                <button type="submit">Verstuur</button>
            </form>
        </div>
    );
}

export default DashboardWijtmanMachineAanmaken;
