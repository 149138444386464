import React, { useState } from 'react';
import './DashboardWijtmanAanmeldformulier.css';

function DashboardWijtmanAanmeldformulier() {
    const [formData, setFormData] = useState({
        begraafplaats: '',
        datumBegrafenis: '',
        tijdstipBegraven: '',
        tijdstipSluiten: '',
        tijdAuladienst: '',
        grafnummer: '',
        soortGraf: '',
        religie: '',
        grafOpVoorraad: '',
        naamOverledene: '',
        diepte: '',
        overputten: '',
        ruimen: '',
        aantalOverledenen: '',
        schudden: '',
        monumentVerwijderen: '',
        doorSteenhouwer: '',
        naastgelegenGraven: '',
        asbussenInGraf: '',
        liftPlaatsen: '',
        dalenMetTouwen: '',
        grafgroen: '',
        emmertjeZand: '',
        overmaatseKistmaat: '',
        opmerkingen: '',
        overigeBijzonderheden: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData); // You can replace this with a form submission method
    };

    return (
        <div className="DashboardWijtmanAanmeldformulier">
            <h1>Aanmeldformulier</h1>
            <form onSubmit={handleSubmit}>
                {/* Begraafplaats */}
                <label>Begraafplaats:</label>
                <select name="begraafplaats" value={formData.begraafplaats} onChange={handleChange}>
                    <option value="Oud">Oud</option>
                    <option value="Nieuw">Nieuw</option>
                </select>

                {/* Datum begrafenis */}
                <label>Datum begrafenis:</label>
                <select name="datumBegrafenis" value={formData.datumBegrafenis} onChange={handleChange}>
                    <option value="Ma">Maandag</option>
                    <option value="Di">Dinsdag</option>
                    <option value="Wo">Woensdag</option>
                    <option value="Do">Donderdag</option>
                    <option value="Vrij">Vrijdag</option>
                    <option value="Za">Zaterdag</option>
                    <option value="Zo">Zondag</option>
                </select>

                {/* Tijdstip begraven */}
                <label>Tijdstip begraven:</label>
                <input
                    type="time"
                    name="tijdstipBegraven"
                    value={formData.tijdstipBegraven}
                    onChange={handleChange}
                />

                {/* Tijdstip sluiten */}
                <label>Tijdstip sluiten:</label>
                <input
                    type="time"
                    name="tijdstipSluiten"
                    value={formData.tijdstipSluiten}
                    onChange={handleChange}
                />

                {/* Tijd Auladienst */}
                <label>Tijd Auladienst:</label>
                <input
                    type="time"
                    name="tijdAuladienst"
                    value={formData.tijdAuladienst}
                    onChange={handleChange}
                />

                {/* Grafnummer */}
                <label>Grafnummer:</label>
                <input
                    type="text"
                    name="grafnummer"
                    value={formData.grafnummer}
                    onChange={handleChange}
                />

                {/* Soort graf */}
                <label>Soort graf:</label>
                <select name="soortGraf" value={formData.soortGraf} onChange={handleChange}>
                    <option value="Alg">Algemeen</option>
                    <option value="Part">Particulier</option>
                    <option value="Bijzetting">Bijzetting</option>
                    <option value="Grafkelder">Grafkelder</option>
                    <option value="Kindergraf">Kindergraf</option>
                </select>

                {/* Religie */}
                <label>Religie:</label>
                <select name="religie" value={formData.religie} onChange={handleChange}>
                    <option value="Islamitisch">Islamitisch</option>
                    <option value="Joods">Joods</option>
                </select>

                {/* Graf op voorraad */}
                <label>Graf op voorraad:</label>
                <select name="grafOpVoorraad" value={formData.grafOpVoorraad} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Naam overledene */}
                <label>Naam overledene:</label>
                <input
                    type="text"
                    name="naamOverledene"
                    value={formData.naamOverledene}
                    onChange={handleChange}
                />

                {/* Diepte */}
                <label>Diepte:</label>
                <select name="diepte" value={formData.diepte} onChange={handleChange}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                </select>

                {/* Overputten */}
                <label>Overputten:</label>
                <select name="overputten" value={formData.overputten} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Ruimen */}
                <label>Ruimen:</label>
                <select name="ruimen" value={formData.ruimen} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Aantal overledenen */}
                <label>Aantal overledenen:</label>
                <input
                    type="number"
                    name="aantalOverledenen"
                    value={formData.aantalOverledenen}
                    onChange={handleChange}
                />

                {/* Schudden */}
                <label>Schudden:</label>
                <select name="schudden" value={formData.schudden} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Monument verwijderen */}
                <label>Monument verwijderen:</label>
                <select name="monumentVerwijderen" value={formData.monumentVerwijderen} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Door steenhouwer */}
                <label>Door steenhouwer:</label>
                <select name="doorSteenhouwer" value={formData.doorSteenhouwer} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Naastgelegen graven */}
                <label>Naastgelegen graven:</label>
                <input
                    type="text"
                    name="naastgelegenGraven"
                    value={formData.naastgelegenGraven}
                    onChange={handleChange}
                />

                {/* Asbussen in het graf */}
                <label>Asbussen in het graf:</label>
                <select name="asbussenInGraf" value={formData.asbussenInGraf} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Lift plaatsen */}
                <label>Lift plaatsen:</label>
                <select name="liftPlaatsen" value={formData.liftPlaatsen} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Dalen met touwen */}
                <label>Dalen met touwen:</label>
                <select name="dalenMetTouwen" value={formData.dalenMetTouwen} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Grafgroen */}
                <label>Grafgroen:</label>
                <select name="grafgroen" value={formData.grafgroen} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Emmertje zand en schepje */}
                <label>Emmertje zand en schepje:</label>
                <select name="emmertjeZand" value={formData.emmertjeZand} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Overmaatse kistmaat */}
                <label>Overmaatse kistmaat:</label>
                <select name="overmaatseKistmaat" value={formData.overmaatseKistmaat} onChange={handleChange}>
                    <option value="Ja">Ja</option>
                    <option value="Nee">Nee</option>
                </select>

                {/* Opmerkingen */}
                <label>Opmerkingen:</label>
                <textarea
                    name="opmerkingen"
                    value={formData.opmerkingen}
                    onChange={handleChange}
                />

                {/* Overige bijzonderheden */}
                <label>Overige bijzonderheden:</label>
                <textarea
                    name="overigeBijzonderheden"
                    value={formData.overigeBijzonderheden}
                    onChange={handleChange}
                />

                {/* Submit Button */}
                <button type="submit">Verstuur</button>
            </form>
        </div>
    );
}

export default DashboardWijtmanAanmeldformulier;
