import React, { useState } from 'react';
import './DashboardWijtmanPersoneelAanmaken.css'; // Include custom styles

function DashboardWijtmanPersoneelAanmaken() {
    // State for the form fields
    const [name, setName] = useState('');
    const [license, setLicense] = useState('B');
    const [roles, setRoles] = useState([]);

    // Toggle role selection (allowing multiple roles to be selected)
    const toggleRole = (role) => {
        setRoles((prevRoles) =>
            prevRoles.includes(role)
                ? prevRoles.filter((r) => r !== role) // Unselect role if it's already selected
                : [...prevRoles, role] // Add role if it's not selected yet
        );
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you can add logic to handle the submitted data
        console.log({ name, license, roles });
        // Reset form fields after submission
        setName('');
        setLicense('B');
        setRoles([]);
    };

    return (
        <div className="DashboardWijtmanPersoneel">
            <h1>Aanmaken</h1>
            <br/>
            <br/>
            <br/>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>
                        Naam:
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </label>
                </div>

                <div className="license-selection">
                    <p>Rijbewijs:</p>
                    <div className="license-boxes">
                        <div
                            className={`box ${license === 'B' ? 'selected' : ''}`}
                            onClick={() => setLicense('B')}
                        >
                            B
                        </div>
                        <div
                            className={`box ${license === 'BE' ? 'selected' : ''}`}
                            onClick={() => setLicense('BE')}
                        >
                            BE
                        </div>
                    </div>
                </div>

                <div className="role-selection">
                    <p>Rol:</p>
                    <div className="role-boxes">
                        <div
                            className={`box ${roles.includes('Machinist') ? 'selected' : ''}`}
                            onClick={() => toggleRole('Machinist')}
                        >
                            Machinist
                        </div>
                        <div
                            className={`box ${roles.includes('Tweede man') ? 'selected' : ''}`}
                            onClick={() => toggleRole('Tweede man')}
                        >
                            Tweede man
                        </div>
                    </div>
                </div>

                <button type="submit">Aanmaken</button>
            </form>
        </div>
    );
}

export default DashboardWijtmanPersoneelAanmaken;
