import React, { useState } from 'react';
import './DashboardWijtmanDagformulier.css';

function DashboardWijtmanDagformulier() {
    const [formData, setFormData] = useState({
        voertuigen: {
            boki: false,
            nimos: false,
            shovel: false,
            rupskraan: false,
        },
        week: '',
        datum: '',
        dag: '',
        werkzaamheden: [
            { afkorting: '', grafnummer: '', tijdBesteed: '' }
        ],
        overkoepelendeNamen: [
            { locatie: '', vertrektijd: '', aankomsttijd: '', reistijd: '', relatiebeheerTijd: '' }
        ]
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            voertuigen: {
                ...prevState.voertuigen,
                [name]: checked
            }
        }));
    };

    const handleWerkzaamhedenChange = (index, field, value) => {
        const updatedWerkzaamheden = [...formData.werkzaamheden];
        updatedWerkzaamheden[index][field] = value;
        setFormData(prevState => ({
            ...prevState,
            werkzaamheden: updatedWerkzaamheden
        }));
    };

    const addWerkzaamheid = () => {
        setFormData(prevState => ({
            ...prevState,
            werkzaamheden: [
                ...prevState.werkzaamheden,
                { afkorting: '', grafnummer: '', tijdBesteed: '' }
            ]
        }));
    };

    const removeWerkzaamheid = (index) => {
        const updatedWerkzaamheden = formData.werkzaamheden.filter((_, i) => i !== index);
        setFormData(prevState => ({
            ...prevState,
            werkzaamheden: updatedWerkzaamheden
        }));
    };

    const handleOverkoepelendeNamenChange = (index, field, value) => {
        const updatedOverkoepelendeNamen = [...formData.overkoepelendeNamen];
        updatedOverkoepelendeNamen[index][field] = value;
        setFormData(prevState => ({
            ...prevState,
            overkoepelendeNamen: updatedOverkoepelendeNamen
        }));
    };

    const addOverkoepelendeNaam = () => {
        setFormData(prevState => ({
            ...prevState,
            overkoepelendeNamen: [
                ...prevState.overkoepelendeNamen,
                { locatie: '', vertrektijd: '', aankomsttijd: '', reistijd: '', relatiebeheerTijd: '' }
            ]
        }));
    };

    const removeOverkoepelendeNaam = (index) => {
        const updatedOverkoepelendeNamen = formData.overkoepelendeNamen.filter((_, i) => i !== index);
        setFormData(prevState => ({
            ...prevState,
            overkoepelendeNamen: updatedOverkoepelendeNamen
        }));
    };


    return (
        <div className="DashboardWijtman">
            <h2>Dashboard Wijtman</h2>
            {/* Week */}
            <label>Week:</label>
            <input
                type="number"
                name="week"
                value={formData.week}
                onChange={handleInputChange}
            />

            {/* Datum */}
            <label>Datum:</label>
            <input
                type="date"
                name="datum"
                value={formData.datum}
                onChange={handleInputChange}
            />

            {/* Dag */}
            <label>Dag:</label>
            <input
                type="text"
                name="dag"
                value={formData.dag}
                onChange={handleInputChange}
            />
            {/* Voertuigen */}
            <label>Voertuigen:</label>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="boki"
                        checked={formData.voertuigen.boki}
                        onChange={handleCheckboxChange}
                    />
                    BOKI
                </label>
                <label>
                    <input
                        type="checkbox"
                        name="nimos"
                        checked={formData.voertuigen.nimos}
                        onChange={handleCheckboxChange}
                    />
                    Nimos
                </label>
                <label>
                    <input
                        type="checkbox"
                        name="shovel"
                        checked={formData.voertuigen.shovel}
                        onChange={handleCheckboxChange}
                    />
                    Shovel
                </label>
                <label>
                    <input
                        type="checkbox"
                        name="rupskraan"
                        checked={formData.voertuigen.rupskraan}
                        onChange={handleCheckboxChange}
                    />
                    Rupskraan
                </label>
            </div>

            <form>
            {/* Overkoepelende Namen */}
            <label>Overkoepelende Namen:</label>
            {formData.overkoepelendeNamen.map((overkoepelendeNaam, index) => (
                <div key={index} className="overkoepelende-naam-entry">
                    <label>Locatie:</label>
                    <input
                        type="text"
                        name={`locatie-${index}`}
                        value={overkoepelendeNaam.locatie}
                        onChange={(e) => handleOverkoepelendeNamenChange(index, 'locatie', e.target.value)}
                    />

                    <label>Aankomsttijd:</label>
                    <input
                        type="time"
                        name={`aankomsttijd-${index}`}
                        value={overkoepelendeNaam.aankomsttijd}
                        onChange={(e) => handleOverkoepelendeNamenChange(index, 'aankomsttijd', e.target.value)}
                    />
                    <label>Vertrektijd:</label>
                    <input
                        type="time"
                        name={`vertrektijd-${index}`}
                        value={overkoepelendeNaam.vertrektijd}
                        onChange={(e) => handleOverkoepelendeNamenChange(index, 'vertrektijd', e.target.value)}
                    />



                    <label>Opmerkingen</label>
                    <input
                        type="text"
                        name={`relatiebeheerTijd-${index}`}
                        value={overkoepelendeNaam.relatiebeheerTijd}
                        onChange={(e) => handleOverkoepelendeNamenChange(index, 'relatiebeheerTijd', e.target.value)}
                    />

                    <button type="button" onClick={() => removeOverkoepelendeNaam(index)}>Remove</button>
                </div>
            ))}
            <button type="button" onClick={addOverkoepelendeNaam}>Add Overkoepelende Naam</button>
           </form>
            <form>

                {/* Werkzaamheden */}
                <label>Werkzaamheden:</label>
                {formData.werkzaamheden.map((werkzaamheid, index) => (
                    <div key={index} className="werkzaamheid-entry">
                        <label>Afkorting Werkzaamheid:</label>
                        <input
                            type="text"
                            name={`afkorting-${index}`}
                            value={werkzaamheid.afkorting}
                            onChange={(e) => handleWerkzaamhedenChange(index, 'afkorting', e.target.value)}
                        />

                        <label>Grafnummer:</label>
                        <input
                            type="text"
                            name={`grafnummer-${index}`}
                            value={werkzaamheid.grafnummer}
                            onChange={(e) => handleWerkzaamhedenChange(index, 'grafnummer', e.target.value)}
                        />

                        <label>Tijd Besteed Werkmodule:</label>
                        <input
                            type="number"
                            name={`tijdBesteed-${index}`}
                            value={werkzaamheid.tijdBesteed}
                            onChange={(e) => handleWerkzaamhedenChange(index, 'tijdBesteed', e.target.value)}
                        />

                        <button type="button" onClick={() => removeWerkzaamheid(index)}>Remove</button>
                    </div>
                ))}
                <button type="button" onClick={addWerkzaamheid}>Add Werkzaamheid</button>

                {/* Submit button */}
                <button type="submit">Submit</button>




            </form>
        </div>
    );
}

export default DashboardWijtmanDagformulier;


// <label>Reistijd:</label>
// <input
//     type="number"
//     name={`reistijd-${index}`}
//     value={overkoepelendeNaam.reistijd}
//     onChange={(e) => handleOverkoepelendeNamenChange(index, 'reistijd', e.target.value)}
// />
//TODO Move calculation to the backend

