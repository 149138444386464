import React from 'react';

function DashboardContactPersonen() {
    return (
        <div className="DashboardContactPersonen">
            {/* Your application code here */}
        </div>
    );
}

export default DashboardContactPersonen;
