// DashboardHome.js
import React from 'react';
import './DashboardHome.css'; // Add styles if needed

function DashboardHome() {
    return (
        <div className="dashboard-home">
            <section className="dashboard-stats">
                <div className="stat-card">
                    <h2>Total Users</h2>
                    <p>1,234</p>
                </div>
                <div className="stat-card">
                    <h2>Active Users</h2>
                    <p>567</p>
                </div>
                <div className="stat-card">
                    <h2>Revenue</h2>
                    <p>$12,345</p>
                </div>
            </section>

            <section className="dashboard-charts">
                <div className="chart">
                    <h3>User Growth</h3>
                    {/* Placeholder for User Growth Chart */}
                </div>
                <div className="chart">
                    <h3>Sales Report</h3>
                    {/* Placeholder for Sales Report Chart */}
                </div>
            </section>

            <section className="dashboard-table">
                <h3>Recent Activity</h3>
                <table>
                    <thead>
                    <tr>
                        <th>User</th>
                        <th>Action</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>John Doe</td>
                        <td>Logged in</td>
                        <td>2024-09-10</td>
                    </tr>
                    <tr>
                        <td>Jane Smith</td>
                        <td>Purchased Item</td>
                        <td>2024-09-09</td>
                    </tr>
                    </tbody>
                </table>
            </section>
        </div>
    );
}

export default DashboardHome;
