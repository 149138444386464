import React from 'react';

function DashboardKlanten() {
    return (
        <div className="DashboardKlanten">




        </div>
    );
}

export default DashboardKlanten;
