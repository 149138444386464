import React from 'react';
import { Link } from 'react-router-dom';
import './WorkCategories.css';

const categories = [
    { title: 'Navigeer naar', link: '/link' },
    { title: 'Navigeer naar', link: '/link' },

];

const WorkCategories = () => (
    <div className="work-categories-container">
        <div className="category-buttons-container">
            {categories.map((category) => (
                <div key={category.link} className="category-item">
                    <Link to={category.link} className="category-link-item">
                        {category.title}
                    </Link>
                </div>
            ))}
        </div>
        <section className="overview-section">
            <div className="overview-message">
                <h2 className="overview-message-title">Lorem ipsum.</h2>
                <p>
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis dicta dignissimos doloremque fugiat impedit ipsum nam quae quisquam ullam voluptates.                </p>
            </div>
            <div className="category-buttons">
                <Link to="/open-sollicitatie" className="category-button">
                    Lorem ipsum.
                </Link>
                <Link to="/vacature-bank" className="category-button">
                    Lorem ipsum.
                </Link>

            </div>
        </section>

    </div>
);

export default WorkCategories;
